/**
 * LoginGuard class
 *
 * @class LoginGuard
 *
 * @description
 * This guard ensures security for non-ReadyView user pages,
 * located in the public folder. It checks if the user is logged in
 * and redirects them to the appropriate page based on their authentication status.
 *
 * @author Brady Synstelien
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { Router } from "@angular/router";
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UserProfileService } from '../services/user-profile.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard {
  
  // Injecting AngularFireAuth and Router in the constructor
  constructor(
    private auth: AngularFireAuth, 
    private router: Router, 
    private userProfile: UserProfileService
  ) {}
  
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {

    // Check if there are any query parameters present in the URL
    const queryParams = route.queryParamMap.keys;
    if (queryParams.length > 0) {
      return true;
    }

    // Use AngularFireAuth to check if the user is logged in
    const user = await firstValueFrom(this.auth.authState)

    if (user) {
      try {
        // If the user is logged in, get the user profile object
        const userProfile = await firstValueFrom(this.userProfile.userObject)
        if (userProfile && userProfile.organization_id) {
          // If userProfile exists, the user is fully authenticated, redirect to dashboard
          return this.router.createUrlTree(['/app/dashboard']);
        } else {
          // userProfile doesn't exist, so let them proceed with the current navigation
          return true;
        }
      } catch (error) {
        // In case of an error fetching the user profile, log and allow the navigation
        console.error('Error fetching user profile:', error);
        return true;
      }
    } else {
      // No user is logged in, so let them proceed with the current navigation
      return true;
    }
  }
}
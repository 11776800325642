// Importing required dependencies and services for this file
import { Component, OnInit } from '@angular/core';
import { UpdateReadyviewService } from './services/update-readyview.service';
import { Storage } from "@ionic/storage";
import { AuthenticationService } from './services/authentication.service';
import { UserProfileService } from 'src/app/services/user-profile.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

// AppComponent is the root component of the application, which acts as the main layout file
export class AppComponent implements OnInit {
  // Variables used in the component
  public display: boolean = true
  public checkLogOut: boolean = false;
  public userIsSet: boolean = false

  // Injecting required services via the constructor
  constructor(
    private authenticationService: AuthenticationService,
    // private sw: UpdateReadyviewService,
    private storage: Storage,
	  private userProfile: UserProfileService,
  ) {}

  async ngOnInit() {
    // // Checking for any updates when the component is initialized
    // this.sw.checkForUpdates();

    // Initialization of the storage
    await this.storage.create()

    // If a user profile exists in the storage, set it and enable authentication
    this.storage.get("user_profile")
      .then(async (data) => {
        await this.userProfile.setUserObject(data);
        // this.ionicService.menuCtrl.enable(data as boolean)
        if (data) {
          this.authenticationService.setAuthenticationState(true);
        }
      }
    )

    // If a theme is stored in the storage, set it and change the document body's attribute accordingly
    this.storage.get("theme").then((val) => {
      if (val) {
        document.body.setAttribute("color-theme", "dark");
      }
    });  

      // If a token exists in the storage, set it to the user profile
      this.storage.get("token").then(async (data) => {    
        await this.userProfile.setToken(data);    
      })

      this.userProfile.getUserObservable().subscribe(
        {
          next: (user) => {
            this.userIsSet = user != undefined
          }
        }
      )

  }

  // Function to toggle the checkLogOut variable
  setCheckLogOut(): void {
    this.checkLogOut = !this.checkLogOut;
  }
  
  // Function to handle logout
  async logout() {
    await this.authenticationService.logout();
    // After logging out, redirect the user to the login page
    window.location.href = '/login';
  }
}
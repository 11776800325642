/**
 * AuthenticationGuard class
 *
 * @class AuthenticationGuard
 *
 * @description
 * This guard ensures security for ReadyView user pages only, 
 * located in the members folder.
 * It checks if the user is authenticated before routing to the destination.
 * If the user is not authenticated, they are redirected to the login page.
 *
 * @author Brady Synstelien
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, firstValueFrom, map, take } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { AuthenticationService } from "src/app/services/authentication.service";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard {
  
  // Injecting AngularFireAuth, AuthenticationService, Router and UserProfileService in the constructor
  constructor(private auth: AngularFireAuth, private authService: AuthenticationService, private router: Router, private userProfile: UserProfileService) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree | Observable<boolean | UrlTree>> {
      // Check if the user is authenticated using AngularFireAuth
      // Use AngularFireAuth to check if the user is logged in
    const user = await firstValueFrom(this.auth.authState)

    if (user) {
      try {
        // If the user is logged in, get the user profile object
        const userProfile = await firstValueFrom(this.userProfile.userObject)
        if (userProfile && userProfile.organization_id) {
          // If userProfile exists, the user is fully authenticated, redirect to dashboard
          return true
        } else {
          // userProfile doesn't exist, so let them proceed with the current navigation
          return this.router.parseUrl('/login')
        }
      } catch (error) {
        // In case of an error fetching the user profile, log and allow the navigation
        console.error('Error fetching user profile:', error);
        return this.router.parseUrl('/login') 
      }
    } else {
      // No user is logged in, so let them proceed with the current navigation
      return this.router.parseUrl('/login') 
    }
  }
  
}
